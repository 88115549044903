import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import WindowPane from  "../../components/windowpane/windowpane"
import Savings from "../../components/savings/savings";

import gradientOne from "../../images/safety-and-side-effects/gradientOne.png"
import gradientTwo from "../../images/safety-and-side-effects/gradientTwo.svg"
import ctaBackground from "../../images/safety-and-side-effects/ctaBackground.svg"

import doctorPatient from "../../images/safety-and-side-effects/3.2_750x380_Patient_Doctor_Desktop_1X.png"
import doctorPatientMobile from "../../images/safety-and-side-effects/3.2_750x380_Patient_Doctor_Desktop_1X.png"

import desktopWindow from "../../images/safety-and-side-effects/vascepaStatin.png"
import mobileWindow from "../../images/safety-and-side-effects/vascepaStatinMobile.png"



export default () => (
  <Layout>
    <SEO
      title="Learn About Safety & Side Effects"
      keywords="VASCEPA side effects, VASCEPA safety, is VASCEPA safe"
      description="Learn about the VASCEPA® (icosapent ethyl) safety profile and possible side effects. Talk to your doctor before beginning treatment. Please see Indication and Important Safety Information."
      />

      <Hero
        video ={true}
        mobileIframe={[<iframe className="mobileHeroBackgroundVideo"  src="https://player.vimeo.com/video/400606187?background=1" width="640" height="943" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>]}
        desktopIframe={[<iframe className="desktopHeroBackground" src="https://player.vimeo.com/video/400606170?background=1" width="1440" height="540" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>]}
        headline={"VASCEPA has an established safety profile"}
        alt="A clear VASCEPA® (icosapent ethyl) heart"
        content={[<span style={{fontSize:"18px",lineHeight:"24px"}}>VASCEPA has been studied in multiple trials and has been available for prescription since 2013.</span>,<br/>,<br/>,<span style={{fontSize:"18px",lineHeight:"24px"}}>As with all medications, before you begin treatment, it’s important to talk to your doctor about possible side effects. Here are a few key points to help guide your conversation.</span>]}
        additionalClassName = "height640 heart-animation"
        />

        <WindowPane
        mobileImage={mobileWindow}
        desktopImage={desktopWindow}
        alt="Clear VASCEPA® (icosapent ethyl) capsule and a pill bottle of statins"
        whiteBorderOne="whiteborderRight side-effects"
        whiteBorderTwo="whiteborderTop"
        backgroundOne={gradientOne}
        backgroundTwo={gradientTwo}
        colorOne = ""
        headlineOne = "Other medications + VASCEPA"
        contentOne = "You might already be taking medications to manage CV risk factors. In clinical trials, patients taking VASCEPA were also taking other common CV medicines, such as blood-thinning medications (e.g., aspirin) and antihypertensives (beta blockers, ACE inhibitors, and ARBs). If you take medicines that affect your blood clotting (anticoagulants or blood thinners), your doctor will want to monitor you. Serious bleeding can happen in people who take VASCEPA. Your risk of bleeding may increase if you are also taking blood thinner medicine."
        contentTwo = {["Remember:", 
                      <br/>,"VASCEPA is FDA-approved to be taken with your statin.",
                      <br/>,<span class="footnote">Capsule is not actual size.</span>
                      ]}

        colorTwo = "#1D355E"
        customMargin= "side-effects-header"
        headlineTwo = {["Let’s talk about side effects. "]}
        contentThree = "In patients with risk factors for CV disease, serious side effects can include bleeding and heart rhythm problems that can cause hospitalization. Tell your doctor if you get any symptoms of joint pain, swelling of the hands, legs, or feet, constipation, gout, and irregular heartbeat. Symptoms of heart rhythm problems include feeling as if your heart is beating fast and irregular, lightheadedness, dizziness, shortness of breath, chest discomfort or you faint."
        contentFour = ""
        mobileHeroBackground=""
        />


      <LeftCta
          ctaBackground = {ctaBackground}
          colOne="5"
          colTwo="7"
          whiteborder="transparentborder"
          image={doctorPatient}
          mobileImage={doctorPatient}
          alt="Man discussing his cardiovascular risk with his doctor and the benefits of VASCEPA® (icosapent ethyl)"
          backgroundColor = "#E7E8EA"
          headline="Talk to your doctor about adding VASCEPA to your heart protection plan."
          cta={["Download the guide ", <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          contentSubClass="left"
          ctaSubClass="left"
          url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
          external="true"

          />








  </Layout>
)
