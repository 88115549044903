import React from "react";
import { Link } from "gatsby"
import "./windowpane.css"

import sanitizeHtml from "sanitize-html"


const WindowPane = (props) => {
    return (
      <div className="windowpane-outer">

        <div className={"block gradient-alt1 row " + props.mobileClass}>
          <div style={{background: props.leftBackground}} className={"col-md-5 block-image " + props.whiteBorderOne}>
            <img className={"mobileImage " + props.mobileHeroBackground} src={props.mobileImage} alt={props.alt}></img>
            <img className="desktopImage"src={props.desktopImage} alt={props.alt}></img>
          </div>
          <div className="col-md-7 block-content">
            <div style={{color: props.colorOne}} className="block-headline-group py-5">
              {props.backgroundOne ? <img className="background-gradient" alt="" src={props.backgroundOne}></img> : null }
              <h3 className="fw-700">{props.headlineOne}</h3>
              <p className="fs-18 lh-22 fw-500 mt-4 mb-4">{props.contentOne}</p>
              <p className="fs-24 lh-30 fw-700 mt-4 mb-4">{props.contentTwo}</p>
            </div>
            <div style={{color: props.colorTwo,background: props.backgroundColorTwo}} className={"block-secondary block-with-icon " + props.whiteBorderTwo}>
            {props.backgroundTwo ? <img className="background-gradient" alt="" src={props.backgroundTwo}></img> : null }
            {props.headlineTwo ? <h5 className={"fs-24 lh-30 fw-900 " + props.customMargin}>{props.headlineTwo}</h5> : null } 
              <p className="fs-18 lh-22 mt-4 mb-4">{props.contentThree}</p>
              {props.contentFour ? <p className="fs-18 lh-22 mt-4 mb-5">{props.contentFour}</p> : null}
              
              {props.footer ? <p className="fs-10 lh-12 mt-1 mb-5" style={{textIndent:"-4px"}} dangerouslySetInnerHTML={{__html: sanitizeHtml(props.footer)}}/> : ""}
              
            </div>
          </div>
        </div>

      </div>
    )
}


export default WindowPane
